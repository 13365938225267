<template>
  <div class="page">
    <div class="tbl">
      <div>
        <h1 style="font-size:.6rem; font-weight:bold">404</h1>
        <h3 style="font-size:.35rem; margin-top:.2rem">Not Found</h3>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .page { 
    position: absolute; 
    top: 0; 
    right: 0; 
    bottom: 0; 
    left: 0;
    text-align:center; 
    .tbl {
      display:table;
      height:100%;
      width:100%;
      div {
        display:table-cell;
        vertical-align: middle
      }
    }
  }

</style>
